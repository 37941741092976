@import "./imports";

html, body, #root, .App {
  width: 100%;
  display: block;
}

body {
  margin: 0;
  font-family: proximanova,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --nav-height: 0px
}

#osunavbar {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  background-color: var(--root-background-color);
  color: var(--root-text-color);
  height: calc(100vh); // calc to make dynamic
}

#osunavbar {
  position: relative;
}

table {
  table-layout: fixed;
}

html, body, #root {
  height: 100%;
}

.App {
  height: auto;
  background-color: var(--root-background-color);
}

footer {
  min-height: 280px;
  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.text-transform-none {
  text-transform: none !important;
}
.dark-mode-enabled {
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus  {
    color: black;
    -webkit-text-fill-color: black;
    background-color: rgb(250, 255, 189);
  }
}